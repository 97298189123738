import React from "react";

import Item from "./Item";
import { ContactItem } from "./ContactItem";

import * as styles from "./menu.module.css";
import { NFTItem } from "./NFTItem";

const Menu = ({ pages }) => {
  const allPages = pages.map(page => ({
    to: page.node.fields.slug,
    label: page.node.frontmatter.menuTitle
      ? page.node.frontmatter.menuTitle
      : page.node.frontmatter.title
  }));

  const items = [
    { to: "/articles/", label: "Learn", icon: null },
    { to: "/about/", label: "What we do?", icon: null },
    ...allPages
  ];

  return (
    <ul className={styles.menu}>
      {items.map(item => (
        <Item item={item} key={item.label} icon={item.icon} />
      ))}
      <ContactItem />
    </ul>
  );
};

export { Menu };
