import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import FontFaceObserver from "fontfaceobserver";
import { ModalsProvider } from "../components/ModalsProvider";

import Footer from "../components/Footer";
import FooterForCategory from "../components/Footer/FooterCategory";
import Header from "../components/Header";

import "./global.css";

class Layout extends React.Component {
  constructor() {
    super();

    this.state = {
      font400loaded: false
    };

    if (typeof window !== `undefined`) {
      this.loadFont("font400", "Optima", 400);
      this.loadFont("font400", "Source Code Pro", 400);
    }
  }

  loadFont = (name, family, weight) => {
    const font = new FontFaceObserver(family, {
      weight: weight
    });

    font.load(null, 10000).then(
      () => {
        console.log(`${name} is available`);
        this.setState({ [`${name}loaded`]: true });
      },
      () => {
        console.log(`${name} is not available`);
      }
    );
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            pages: allMarkdownRemark(
              filter: {
                fileAbsolutePath: { regex: "//pages//" }
                frontmatter: { menuTitle: { eq: "null" } }
              }
            ) {
              edges {
                node {
                  fields {
                    slug
                  }
                  frontmatter {
                    date
                    title
                    menuTitle
                  }
                }
              }
            }
            footnote: markdownRemark(fileAbsolutePath: { regex: "/footnote/" }) {
              id
              html
            }
          }
        `}
        render={data => {
          const { children } = this.props;
          const {
            footnote: { html: footnoteHTML },
            pages: { edges: pages }
          } = data;

          if (children.key === "/hello/") {
            return (
              <ModalsProvider>
                <main>{children}</main>
              </ModalsProvider>
            );
          }

          const isCategoryPage = children?.key?.includes("/category/");

          return (
            <ModalsProvider>
              <Header pages={pages} path={this.props.location.pathname} />
              <main>{children}</main>
              {!isCategoryPage && <Footer html={footnoteHTML} />}
              {isCategoryPage && <FooterForCategory html={footnoteHTML} />}
            </ModalsProvider>
          );
        }}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default Layout;
