import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import * as styles from "./item.module.css";

const Item = props => {
  const { item: { label, to, icon: Icon } = {}, onClick } = props;

  return (
    <li className={styles.menuItem}>
      <Link
        to={to}
        data-slug={to}
        onClick={onClick}
        className={styles.itemLink}
        activeClassName={styles.activeLink}
      >
        {Icon && <Icon />} {label}
      </Link>
    </li>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.any,
};

export default Item;
