export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validate = (key, value) => {
  if (key === 'name' && value.length === 0) {
    return 'Please input your name!';
  }
  if (key === 'email' && !validateEmail(value)) {
    return 'Please input your e-mail address!';
  }
  if (key === 'role' && value.length === '') {
    return 'Please choose your role!';
  }
  return false;
}