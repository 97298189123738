import React from "react";
import Item from "../Menu/Item";
import { ContactItem } from "../Menu/ContactItem";

import { ImFileText2 } from "react-icons/im";
import { BiCommentDetail } from "react-icons/bi";

import * as styles from "./footerMenu.module.css";

const FooterMenu = () => {
  const items = [
    { to: "/articles/", label: "Articles", icon: ImFileText2 },
    { to: "/about", label: "About", icon: BiCommentDetail },
  ];

  return (
    <div className={styles.menuWrapper}>
      <ul className={styles.menu}>
        {items.map(item => (
          <Item item={item} key={item.label} icon={item.icon} />
        ))}
        <ContactItem icon />
      </ul>
    </div>
  );
};

export { FooterMenu };
