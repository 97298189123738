import Modal from 'react-responsive-modal';
import React, { useCallback, useMemo, useReducer } from 'react';
import { initialModalsState, ModalNames, ModalContext } from './const';

import { ConfirmationModal } from '../ConfirmationModal';
import { ContactMeModalContent } from '../ContactMeModal/ContactMeModalContent';

import * as styles from './modalsProvider.module.css';

const modalsReducer = (state, { isOpen, modalName }) => {
  return {
    ...state,
    [modalName]: {
      isOpen,
    },
  };
};

const ModalsProvider = ({ children }) => {
  const [modalsState, dispatch] = useReducer(modalsReducer, initialModalsState);

  const openModal = useCallback((modalName) => {
    dispatch({
      isOpen: true,
      modalName,
    });
  }, []);

  const closeModal = useCallback((modalName) => {
    dispatch({
      isOpen: false,
      modalName,
    });
  }, []);

  const providerValue = useMemo(() => ({
    openModal,
  }), [openModal]);

  const handleCloseContactModal = () => {
    closeModal(ModalNames.TO_CONTACT);
  }

  const handleCloseConfirmationModal = () => {
    closeModal(ModalNames.CONFIRMATION);
  }

  return (
    <>
      <ModalContext.Provider value={providerValue}>
        {children}

        <Modal
          classNames={{
            modal: styles.contactModalWrapper,
          }}
          showCloseIcon={false}
          onClose={handleCloseContactModal}
          open={modalsState[ModalNames.TO_CONTACT].isOpen}
        >
          <ContactMeModalContent onCLose={handleCloseContactModal} />
        </Modal>
      </ModalContext.Provider>
      <Modal
        center
        classNames={{
          modal: styles.contactModalWrapper,
        }}
        showCloseIcon={false}
        onClose={handleCloseConfirmationModal}
        open={modalsState[ModalNames.CONFIRMATION].isOpen}
      >
        <ConfirmationModal onCLose={handleCloseConfirmationModal} />
      </Modal>
    </>
  );
};

export { ModalsProvider };
