import React from 'react';
import { ShareLinks } from '../Share';
import CrossIcon from "../../images/svg-icons/crossIcon.svg";

import * as styles from './confirmationModal.module.css';

export const ConfirmationModal = ({ onCLose }) => {
  return (
    <div className={styles.confirmationModalContent}>
      <div className={styles.closeButtonWrap}>
        <button className={styles.closeButton} onClick={onCLose}>
          <CrossIcon className={styles.closeIcon} />
        </button>
      </div>
      <h1 className={styles.thanksTitle}>Thanks for getting in touch! I'll get back to you soon.</h1>
      <div className={styles.socialWrap}>
        <h3 className={styles.titleOfSicial}>You can contact me in:</h3>
        <ShareLinks darkTheme={false} whiteColor={true} />
      </div>
    </div>
  );
};
