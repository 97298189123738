import React from "react";
import { ModalNames, useModalsContext } from "../ModalsProvider";
import * as styles from "../Menu/item.module.css";
import { VscCallIncoming } from "react-icons/vsc";

const ContactItem = ({ icon }) => {
  const { openModal } = useModalsContext();

  const handleOpenModal = () => {
    openModal(ModalNames.TO_CONTACT);
  };
  return (
    <li className={styles.menuItem}>
      <a className={styles.itemLink} onClick={handleOpenModal}>
        {icon && <VscCallIncoming />}
        Contact
      </a>
    </li>
  );
};

export { ContactItem };
