import React from "react";
import * as styles from "../Menu/item.module.css";
import NFTIcon from "../../images/app-icons/NFT.png";

const NFTItem = ({ icon }) => (
  <li className={styles.menuItem}>
    <a
      className={`${styles.itemLink} ${styles.NFTItem}`}
      href="https://nft.kobvel.com/"
      target="_blank"
      rel="noreferrer"
    >
      {icon && <img className={styles.NFTIcon} src={NFTIcon} alt="" />}
      NFT
    </a>
  </li>
);

export { NFTItem };
