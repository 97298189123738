import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Menu } from "../Menu/Menu";
import config from "../../../content/meta/config";
import { ScheduleCallButton } from "../ContactMeModal/ScheduleCallButton";

import * as styles from "./header.module.css";

const Header = (
  {
    pages,
    path
  }
) => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <Link to="/" className={styles.titleOfPage}>
          {config.headerTitle}
        </Link>
        <Menu
          pages={pages}
        />
      </div>
    </header>
  );
}

Header.propTypes = {
  pages: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
};

export default Header;
