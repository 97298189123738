module.exports = {
  siteTitle: "Kobvel.com - Mikki Kobvel",
  shortSiteTitle: "Mikki Kobvel - Top Software Solutions for Startups", // <title> ending for posts and pages
  siteDescription: "Building Software products with the philosophy in mind.",
  siteUrl: "https://kobvel.com",
  pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorEmail: "mikki@kobvel.com",
  authorName: "Mikki Kobvel",
  authorTwitterAccount: "kobvel",

  /* info */
  headerTitle: "Kobvel Software",
  headerSubTitle: "Hire Remote Engineers",

  /* manifest.json */
  manifestName: "Mikki Kobvel",
  manifestShortName: "MikkiKobvel", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",
  gravatarImgMd5: "",

  socialLinks: {
    linkedIn: 'https://www.linkedin.com/in/kobvel/',
    facebook: 'https://www.facebook.com/kobvel',
    twitter: 'https://twitter.com/kobvel',
    instagram: 'https://www.instagram.com/kobvel/',
  },

  authorSocialLinks: [
    { name: "github", url: "https://github.com/kobvel" },
    { name: "twitter", url: "https://twitter.com/kobvel" },
    { name: "facebook", url: "https://facebook.com/kobvel" }
  ]
};
