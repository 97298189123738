import React, { useEffect, useRef } from "react";
import ContactForm from "../ContactForm/ContactForm";
import CrossIcon from "../../images/svg-icons/crossIcon.svg";

import * as styles from "./contactMeContent.module.css";

const ContactMeModalContent = ({ onCLose }) => {
  const firstInputEl = useRef(null);

  useEffect(() => {
    firstInputEl.current.focus();
  }, []);

  return (
    <div className={styles.contactModalContent}>
      <div className={styles.closeButtonWrap}>
        <button className={styles.closeButton} onClick={onCLose}>
          <CrossIcon className={styles.closeIcon} />
        </button>
      </div>
      <div className={styles.contentWrapper}>
        <h2 className={styles.titleOfModal}>Contact me and describe how to help you</h2>
        <ContactForm
          refElement={firstInputEl}
          modal={true}
          closeModal={onCLose}
          darkTheme={false}
        />
      </div>
    </div>
  );
};

export { ContactMeModalContent };
