import React from "react";
import config from "../../../content/meta/config";
import { FaInstagram, FaTwitter, FaLinkedinIn } from "react-icons/fa/";

import * as styles from "./shareLinks.module.css";

const ShareLinks = ({ darkTheme, getInTouch, whiteColor }) => {
  return (
    <div
      style={{margin: whiteColor ? "unset" : '0 auto'}}
      className={`${styles.socialLinks} ${darkTheme ? styles.darkTheme : styles.whiteTheme}`}
    >
      <a
        className={`${styles.socialLink} ${whiteColor ? styles.colorWhite : ""}`}
        rel="noreferrer"
        aria-label="LinkedinIn"
        href={config.socialLinks.linkedIn}
        target="_blank"
      >
        <FaLinkedinIn />
      </a>
      <a
        className={`${styles.socialLink} ${whiteColor ? styles.colorWhite : ""}`}
        rel="noreferrer"
        aria-label="Facebook"
        href={config.socialLinks.instagram}
        target="_blank"
      >
        <FaInstagram />
      </a>
      <a
        className={`${styles.socialLink} ${whiteColor ? styles.colorWhite : ""}`}
        rel="noreferrer"
        aria-label="Twitter"
        href={config.socialLinks.twitter}
        target="_blank"
      >
        <FaTwitter />
      </a>
    </div>
  );
};

export { ShareLinks };
