import { createContext } from "react";

export const ModalContext = createContext();

export const ModalNames = {
	TO_CONTACT : 'toContact',
	CONFIRMATION : 'confirmationModal',
}

export const initialModalsState = {
	[ModalNames.TO_CONTACT]: {
		isOpen: false,
	},
  [ModalNames.CONFIRMATION]: {
		isOpen: false,
	},
};

