// extracted by mini-css-extract-plugin
export var arrowSvg = "contactForm-module--arrowSvg--c18a3";
export var contactForm = "contactForm-module--contactForm--660fb";
export var darkTheme = "contactForm-module--darkTheme--bd1a6";
export var emailInputOfForm = "contactForm-module--emailInputOfForm--ba79b";
export var errorMessage = "contactForm-module--errorMessage--d507f";
export var errorWhite = "contactForm-module--errorWhite--54cd8";
export var fieldWrap = "contactForm-module--fieldWrap--03c25";
export var hasError = "contactForm-module--has-error--93c19";
export var inputOfForm = "contactForm-module--inputOfForm--5ab67";
export var nameInputOfForm = "contactForm-module--nameInputOfForm--36023";
export var selectOfForm = "contactForm-module--selectOfForm--ce30b";
export var submitButton = "contactForm-module--submitButton--4cb4c";
export var textAreaOfForm = "contactForm-module--textAreaOfForm--e4f0b";
export var whiteTheme = "contactForm-module--whiteTheme--28c94";