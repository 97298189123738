import React, { useState } from "react";
import { useModalsContext, ModalNames } from "../ModalsProvider";
import { validate } from "./utils";
import RightArrow from "../../images/svg-icons/right.svg";
import cx from "classnames";

import * as styles from "./contactForm.module.css";

const ContactForm = ({ form, darkTheme, closeModal, refElement, modal }) => {
  const { openModal } = useModalsContext();
  const [error, setError] = useState({
    name: null,
    email: null,
    role: null
  });
  const [formValues, setFormValues] = useState(null);

  const style = {
    width: modal && "730px"
  };

  const handleInputChange = (key, value) => {
    const errorMessage = validate(key, value);

    if (errorMessage) {
      setError({
        ...error,
        [key]: errorMessage
      });
    } else {
      setError({
        ...error,
        [key]: null
      });
    }

    const data = {
      ...formValues,
      [key]: value
    };
    setFormValues(data);
  };

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (formValues && !error.name && !error.email && !error.role) {
      console.log("Received values of form: ", formValues);
      sendMessage(formValues);
      setFormValues(null);
    } else {
      setError({
        name: "Please input your name!",
        email: "Please input your e-mail address!",
        role: "Please choose your role!"
      });
    }
  }

  const sendMessage = values => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values })
    })
      .then(() => {
        console.log("Form submission success");
        if (typeof closeModal === "function") {
          closeModal();
        }
        openModal(ModalNames.CONFIRMATION);
        // navigate("/success");
      })
      .catch(error => {
        console.error("Form submission error:", error);
        handleNetworkError();
      });
  };

  function handleNetworkError(e) {
    console.log("submit Error");
  }

  return (
    <form
      name="contact"
      data-netlify="true"
      onSubmit={handleSubmit}
      data-netlify-honeypot="bot-field"
      className={cx(
        styles.contactForm,
        { [styles.darkTheme]: darkTheme },
        { [styles.whiteTheme]: !darkTheme }
      )}
    >
      <div className={styles.fieldWrap}>
        <input
          id="name"
          value={formValues?.name}
          onChange={e => handleInputChange("name", e.target.value)}
          style={style}
          name="name"
          ref={refElement}
          placeholder="Name"
          className={cx(styles.nameInputOfForm, styles.inputOfForm, { [styles.hasError]: error })}
        />
        {error?.name && (
          <div className={`${styles.errorMessage} ${modal ? styles.errorWhite : ""}`}>
            {error.name}
          </div>
        )}
      </div>
      <div className={styles.fieldWrap}>
        <input
          id="email"
          value={formValues?.email}
          onChange={e => handleInputChange("email", e.target.value)}
          style={style}
          name="email"
          placeholder="Email"
          className={cx(styles.emailInputOfForm, styles.inputOfForm, { [styles.hasError]: error })}
        />
        {error?.email && (
          <div className={`${styles.errorMessage} ${modal ? styles.errorWhite : ""}`}>
            {error.email}
          </div>
        )}
      </div>
      <div className={styles.fieldWrap}>
        <select
          value={formValues?.role}
          onChange={e => handleInputChange("role", e.target.value)}
          id="role"
          name="role"
          className={cx(
            { [styles.darkTheme]: darkTheme },
            { [styles.whiteTheme]: !darkTheme },
            { [styles.modalSelect]: modal },
            { [styles.hasError]: error },
            styles.selectOfForm
          )}
          placeholder="Choose your role"
        >
          <option aria-label="Founder" value="Founder">
            Founder
          </option>
          <option aria-label="Engineering manager" value="Engineering manager">
            Engineering manager
          </option>
          <option aria-label="Project manager" value="Project manager">
            Project manager
          </option>
          <option aria-label="Just looking around" value="Just looking around">
            Just looking around
          </option>
        </select>
        {error?.role && (
          <div className={`${styles.errorMessage} ${modal ? styles.errorWhite : ""}`}>
            {error.role}
          </div>
        )}
      </div>
      <div className={styles.fieldWrap}>
        <textarea
          id="message"
          value={formValues?.message}
          onChange={e => handleInputChange("message", e.target.value)}
          style={style}
          name="message"
          placeholder="Message"
          className={cx(styles.textAreaOfForm, styles.inputOfForm, { [styles.hasError]: error })}
        />
      </div>
      <button
        style={{ marginRight: modal && "0", backgroundColor: modal && "#222222" }}
        type="submit"
        className={styles.submitButton}
        aria-label="Submit Button"
      >
        <RightArrow className={styles.arrowIcon} />
      </button>
    </form>
  );
};

export default ContactForm;
