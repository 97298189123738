import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { FooterMenu } from "./FooterMenu";
import * as styles from "./footer.module.css";

const Footer = ({ html }) => {
  return (
    <footer className={styles.footerWrap}>
      <div className="container">
        <div className={styles.copyrightAndSocialWrap}>
          <div className={styles.copyright} dangerouslySetInnerHTML={{ __html: html }} />
          <div className={styles.termsAndPrivacyLinks}>
            <Link className={styles.linkPrivacy} to="/privacy">Privacy Policy</Link>
            <Link className={styles.linkTerms} to="/terms">Terms of use</Link>
          </div>
        </div>
        <FooterMenu />
      </div>
    </footer>
  );
};

Footer.propTypes = {
  html: PropTypes.string,
};

export default Footer;
